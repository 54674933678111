:root {
  --bs-body-font-size: 0.9rem;
}
::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: hsla(0, 0%, 89%, .8);
  border-color: rgba(255,255,255, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}
::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, .1);
}
::-webkit-scrollbar {
  height: 1rem;
  width: .5rem;
}
body {
  background: #0C0F15;
  font-size: 14px;
}
.site-head {
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(255 255 255 / 12%);
}
.btn-primary {
  background: #4398F7;
  font-size: 14px;
  border-color: #4398F7;
}
.btn-outline-primary{
  color: #4398F7;
}
.btn-outline-primary:hover > svg,
.btn-outline-primary:hover > svg>path {
  fill: #fff;
}
.btn-outline-primary:hover{
  background-color: #4398F7;
  color: #fff;
}
.btn-outline-primary svg,
.btn-outline-primary svg path{
  fill: #4398F7;
}
.site-head .logo-wrap {
  max-width: 100px;
}
.page-wrapper .grid-wrapper {
  height: calc(100vh - 60px);
  position: relative;
}
.page-wrapper .grid-wrapper .main-area {
  margin-left: 380px;
  width: calc(100% - 380px);
  height: 100%;
  background: rgb(234 244 246 / 5%);
}
.page-wrapper .sidebar {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
}


.grid-wrapper .sec-heading {
  font-size: 18px;
  color: #fff;
}
.center-area {
  border: 1px solid;
  height: 100%;
  border-top: 0px;
  border-color: rgb(255 255 255 / 7%);
  border-bottom: 0px;
}
.grid-wrapper .main-area>.row{
  height: 100%;
}
.grid-wrapper .main-area .code-area {
  height: calc(100% - 110px);
  margin-top: 25px;
  border-radius: 10px;
  border: 1px solid #2C3138;
}
.code-iframe {
  width: 100%;
  border: 1px solid rgb(255 255 255 / 12%);
  border-radius: 5px;
 height:300px;
}
.ai-quiz {
  
  color: #C4C4C4;
  height: 100%;
  border-radius: 10px;
  position: relative;

  padding-bottom: 100px !important;
}
.grid-container {
  max-width: 800px;
  margin: auto;
}
.ai-quiz .quiz-area {
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;
}
.ai-quiz .bottom-btn {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 93%;
  margin: auto;
  margin-bottom: 19px;
  max-width: 800px;
}

.ai-quiz .bottom-btn .form-control {
  border: 0px;
  color: #fff;
  background: #232C37;
  min-height: 20px;
  min-height: 31px;
  padding-right: 67px;
  font-size: 14px;
  padding-top: 23px;
  line-height: 1.2;
  padding-bottom: 6px;
  resize: none;
}


.ai-quiz .bottom-btn .btn {
  position: absolute;
  right: 12px;
  bottom: 12px;
}
.preview-btn .btn {
  height: 37px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: 38px;
}
.preview-btn .btn-secondary {
  background: transparent;
  border: 1px solid #606b7b;
}
.preview-btn .btn:hover {
  box-shadow: none;
  z-index: inherit;
}
.preview-btn .btn-secondary > svg > path{
  fill: #606b7b;
}
.preview-btn .btn > svg {
  width: 17px;
  height: 17px;
  
}
.h100-per{
  height: 100%;
}
.center-area {
  width: calc(100% - 400px);
}

.right-area {
  width: 400px;
}

